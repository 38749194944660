var $win = $(window),
touch = false,
docHeight,
winWidth,
winHeight,
scrollpane,
coords = [],
heights = [],
isDoneLoading = false,
_qg = {};


_qg.ua = (function(){
	
	var _ua = navigator.userAgent.toLowerCase();
	var _o = {
		ltIE6:typeof window.addEventListener == "undefined" && typeof document.documentElement.style.maxHeight == "undefined",
		ltIE7:typeof window.addEventListener == "undefined" && typeof document.querySelectorAll == "undefined",
		ltIE8:typeof window.addEventListener == "undefined" && typeof document.getElementsByClassName == "undefined",
		IE9: navigator.appVersion.toLowerCase().indexOf("msie 9.") != -1,
		IE10 : navigator.userAgent.match(/Trident\/[6]/i),
		IE11: navigator.userAgent.match(/Trident\/7\./),
		IE:document.uniqueID,
		Firefox:window.sidebar,
		Opera:window.opera,
		chrome: _ua.indexOf('chrome') > -1,
		//Webkit:!document.uniqueID && !window.opera && !window.sidebar && !window.orientation && window.localStorage,
		mobile:/android|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()),
		iphone:/iphone|ipod/i.test(_ua),
		android:/android/.test(_ua),
		ipad:/ipad/.test(_ua),
		tablet:undefined,
		smartphone:undefined
	}
	_o.tablet = _o.ipad;
	if(!_o.tablet && _o.android){ _o.tablet = !(/mobile/.test(_ua));}				
	_o.smartphone = _o.iphone || _o.android ? true : false;
	
	var v = [];
	if (/iP(hone|od|ad)/.test(navigator.platform)) {v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);}
	var vAry = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
	_o.iosV = vAry[0] ? vAry[0] : '';
	
	
	return _o;
})();

function isPassive() {
    var supportsPassiveOption = false;
    try {
        addEventListener("test", null, Object.defineProperty({}, 'passive', {
            get: function () {
                supportsPassiveOption = true;
            }
        }));
    } catch(e) {}
    return supportsPassiveOption;
}
  


	;(function(){
		function id(v){ return document.getElementById(v); }
		function loadbar() {
			if(!$('.loader').length) return false;
			var ovrl = id("overlay"),
					bar = id("loader-bar"),
					bTop = id("loader-top"),
					bBottom = id("loader-bottom"),
					img = document.images,
					c = 0,
					tot = img.length,
					loadingTimer,
					loadingAneme;

			//loaderLogo.style.opacity = 1;

			if(tot == 0) return doneLoading();

			function imgLoaded(){
				
				if(loadingTimer) clearTimeout(loadingTimer);
				loadingTimer = setTimeout(()=>{
					return doneLoading();
				}, 8000);
				c += 1;
				var perc = ((100/tot*c) << 0) +"%";

				$('#loader-bar').css({width: perc});

				if(c===tot) {
					if(loadingTimer) clearTimeout(loadingTimer);
					return doneLoading();
				}
			}
			function doneLoading(){
				//return false;
				if(isDoneLoading) return false;

					isDoneLoading = true;

					init();					
				// }, 1200);
			}
			for(var i=0; i<tot; i++) {
				var tImg     = new Image();
				tImg.onload  = imgLoaded;
				tImg.onerror = imgLoaded;
				tImg.src     = img[i].src;
			}    
		}
		document.addEventListener('DOMContentLoaded', loadbar, false);
	}());
	

$(document).ready(function(){

	resizeHandler();

	let vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);
	//}

	let wst = 0;
	$win.on('resize load', resizeHandler).on('scroll', ()=>{
		wst = $win.scrollTop();
		if(wst > $win.height()){
			$('#footer .button-go-top').addClass('visible');
		} else {
			$('#footer .button-go-top').removeClass('visible');
		}
		if(wst > 200) {
			$('#header').addClass('minimized');
		} else {
			$('#header').removeClass('minimized');
		}
	});

	if($('#header .toggle-nav-button:visible').length){
		var navScrolloptions = {};

		if(_qg.ua.iphone || _qg.ua.ipad) {
			navScrolloptions = {
				// disablePointer: true,
				//click: true
			}
		}
		
		var navScroll = new IScroll('.nav-scroll', navScrolloptions);

		// $('#header #g-nav li a').on('click', (e)=>{
    //   if($(e.currentTarget).parent().find('.child').length){
		// 		e.preventDefault();
		// 		$(e.currentTarget).parent().find('.child').slideToggle();
		// 		setTimeout(()=>{
		// 			navScroll.refresh();
		// 		}, 500);
		// 	}		
		// })
	}

	$('.toggle-nav-button').on('click', ()=>{
		$('#header .toggle-nav-button').toggleClass('opened');
		$('#header #g-nav').slideToggle(400, ()=>{
			if($('#header #g-nav').is(':visible')){
				//alert(isPassive());
				document.addEventListener('touchmove', disableScroll, isPassive() ? {
					capture: false,
					passive: false
				} : false);			
				navScroll.refresh();
			} else {
				//alert(isPassive());
				document.removeEventListener('touchmove', disableScroll, isPassive() ? {
					capture: false,
					passive: false
				} : false);						
			}
		});
	});

	$('#g-nav li').on('mouseenter', function(){
		if(!$('#header .toggle-nav-button:visible').length)
		$(this).find('.child').stop().slideDown(400, 'easeOutCubic');
	});

	$('#g-nav li').on('mouseleave', function(){
		if(!$('#header .toggle-nav-button:visible').length)
		$(this).find('.child').stop().slideUp(100, 'easeOutCubic');
	});

	$('.anchor, #nav-bar a, .local-nav a, .column-index a').on('click', function(e){
		var tar = $(this).attr('href');
		pageScrollTo(tar, e);
	});	

	if($('.side-nav').length && !$('.side-nav').hasClass('not-sticky')){
		let st, headerH;
		let mainContents = $('.main-contents');
		let sideBar = $('.sidebar');
		let sideNav = $('.side-nav');
		let sidebarTop = sideBar.offset().top;
		let pTop = Number($('.contents-wrapper').css('padding-top').replace('px', ''));
		let diff = Number(pTop) + Number($('.contact-section').css('margin-top').replace('px', ''));
		//console.log(diff);

		$win.on('scroll', ()=>{
			st = $win.scrollTop();
			headerH = $('#header').height();
			if(st > sidebarTop - (headerH + pTop)) {
				let navY = headerH + pTop;
				sideNav.css({
					position: 'fixed',
					top: navY,
					bottom: 'auto',
					width: sideBar.width()
				});
				//console.log($('.contact-section').offset().top - st - $win.height())
				if(mainContents.offset().top + mainContents.height() < st + sideNav.height() + diff){
					sideNav.css({
						position: 'fixed',
						top: 'auto',
						bottom: Math.abs($('.contact-section').offset().top - st - $win.height()) + diff,
						width: sideBar.width()
					});			
				}
			} else {
				sideNav.css({
					position: 'static'
				});			
			}
		});
	}

	let speed = 0.2;
	let st = 0;

	if($('.point-header').length) {
		$('.point-header').matchHeight();
	}

	
});

$.event.add(window, "load", function(){	



});

function init() {

	$(window).on('scroll.home', scrollFunc);  

	new typeText($('.theme h1'));	
	new typeText($('.intro-section .lead-text'));	

	let titleAry = [];
	
	// $('.section-title').each((i, elem)=>{
	// 	titleAry.push(new typeText($(elem)));
	// });


	timer(()=>{			
		titleAnimation('.theme h1 .type-char')
	}, 1500);

	timer(()=>{
		$('#loader-bar').css({left: 'auto', right: 0, width: 0});
	}, 800);	

	timer(()=>{
		$('#loader-top, #loader-bottom').css({height: 0});
	}, 1200);	

	timer(()=>{
		$('.loader').remove();
	}, 2000);	

	timer(()=>{
		$('.indicator').addClass('executed');
	}, 3000);		

	timer(()=>{
		$('.dm-banner, .banners').addClass('executed');
	}, 3000);			

	var slider = new Swiper('.hero-slider', {
		loop: true,
		speed: 2000,
		autoplay: {
			delay: 5000,
		},
		autoplayDisableOnInteraction: false,
		effect: 'fade',
		spaceBetween: 0,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		autoplay: {
			disableOnInteraction: false
		}	
	});

	timer(()=>{
		$('.theme').addClass('executed');
	}, 1800)

	// timer(()=>{
	// 	//textAnimation('.theme p .type-char');		
	// 	$('.theme p').css({opacity: 1});	
	// }, 2200);

	timer(()=>{
		$('.news-box').addClass('executed');
	}, 3400);


	let speed = 0.2;
	let st = 0;
	$(window).on('scroll.home', function () {
		st = $(window).scrollTop();					
		$('.hero-section').css({transform: 'translateY(-' + Math.round(st * speed) + 'px)'});
			
		let n = 0;
		$('.prlx').each((i, elem) => {
			let thisY = coords[i];
			let ratio =  0.8;
			if(thisY <= st + winHeight * ratio){
				let str = '';

				if(!$(elem).hasClass('qued')){
					$(elem).addClass('qued');

					if($(elem).hasClass('intro-section')){
						timer(()=>{
							textAnimation('.intro-section .lead-text .type-char')
						}, 800);
					}

					queTimer(elem, n);
					n++;
				}
			}
		
		});

	}); 

	$(window).trigger('scroll.home');

}

function disableScroll(e) {
	e.preventDefault();
}

let ticking2;

function scrollFunc() {
	let speed = 0.2;
	let st = 0;
	// if (!ticking2) {
	// 	requestAnimationFrame(function() {
	// 		ticking2 = false;
			st = $win.scrollTop();
			
		
			$('.hero-section').css({transform: 'translateY(-' + Math.round(st * speed) + 'px)'});

	// 	});
	// 	ticking2 = true;
	// }
}


function titleAnimation(target1){
	anime({
		targets: target1,
		translateY: ()=>{
			return [anime.random(-10, 10),0];
		},
		opacity: [0,1],
		easing: "easeOutQuad",
		duration: 400,
		delay: (el, i) => 300 + 50 * i
	});			
}

function textAnimation(target1){
	anime({
		targets: target1,
		translateY: ()=>{
			return [anime.random(-25, 25),0];
		},
		opacity: [0,1],
		easing: "easeOutQuad",
		duration: 400,
		delay: ()=>{
			return anime.random(200, 500)
		},
	});				
}

function pageScrollTo(tar, e){

	var base = location.protocol + '//' + location.hostname;

	if(location.pathname != '/' && !location.pathname)
	base +=  '/' + location.pathname;

	if(!location.pathname || location.pathname == '/'){
		base += '/';
	}

	var tar = tar.replace(base, '');


	var str = tar.slice(0, 1);

	if(str == '#'){

		if($('#nav-bar').is(':visible') && $('.hamburger-button').is(':visible')){
			$('#nav-bar').slideUp();
		}

		if(e)
		e.preventDefault();

			tar = tar.replace('#', '');

		if($('[data-id="' + tar + '"]').length || tar == 'header'){
			var pos = tar == 'header' ? 0 : $('[data-id="'+tar+'"]').offset().top - 100;

			// if(isHomeFirstLoaded){
			// 	setTimeout(function(){
			// 		$('html,body').stop().animate({ scrollTop: pos }, 1000, 'easeOutExpo');
			// 	}, 2000)				
			// } else {
				$('html,body').stop().animate({ scrollTop: pos }, 1000, 'easeOutExpo');
			//}
		}

	}

}


function timer(func, delay){
	setTimeout(func, delay);
}

const queTimer = (elem, n) => {
	
	setTimeout(()=>{
		$(elem).addClass('executed');
	}, 200 * n);

}


function resizeHandler(){
	winHeight = $win.height();
	coords = [];
	heights = [];
	if($('.prlx').length){
		$(".prlx").each((i, elem)=>{
			coords.push($(elem).offset().top);
			heights.push($(elem).height());
		});
	}

	if($('.side-nav').length && !$('.side-nav').hasClass('not-sticky')){
		$('.side-nav').css({width: $('.sidebar').width()});
	}

	if($('#header .toggle-nav-button:visible').length){
		$('#g-nav .child').attr('style', '');
	} else {
		$('.nav-scroll-contents, #g-nav').attr('style', '');
		$('.nav-scroll-contents, #g-nav').attr('style', '');
		$('.toggle-nav-button').removeClass('opened');
	}

	if($('iframe.at-home').length){
		$('.at-home-wrapper').css({width: $('.contents-wrapper').width()});
	}

}


function typeText(element){
	this._element = element;
	this.defaultCharacters;
	this.originalCharacters;
	this.optionsDefault = {interval: 40, callbackDelay: 0, callback: function(){}}
	this.timeout;
	this.intervals = [];
	this.duration = 600;
	this.initialize.apply( this, arguments);
}

typeText.prototype.initialize = function(element){

	var _self = this;

	var str = this._element.html();

	if(str){

		var regex = /<(".*?"|'.*?'|[^'"])*?>/gi;
		var breaks = str.match(regex);

		var entitiesRegex = /&(?:[a-z\d]+|#\d+|#x[a-f\d]+);/gi;
		var entities = str.match(entitiesRegex);

		var jaStr = str.replace(regex, "");
		jaStr = jaStr.replace(entitiesRegex, "");

		str = str.replace(regex, "@");
		str = str.replace(entitiesRegex, "$");

		//var foo = 'abc123def456ghi';


		var newStr = '';
		var newStrArray = str.split("");
		var newStrLength = newStrArray.length;
		var breakCount = 0;
		var entityCount = 0;
		for(var i = 0; i < newStrLength; i++) {
				if(newStrArray[i] == '@'){
						newStr +=  breaks[breakCount];
						breakCount++;
				} else if(newStrArray[i] == '$'){
						newStr +=  '<ins class="type-char">' + entities[entityCount]  + '</ins>';
						entityCount++;
				} else if(newStrArray[i] == ' ') {
						newStr +=  newStrArray[i];
				} else {
						newStr +=  '<ins class="type-char">' + newStrArray[i]  + '</ins>';
				}
		}

		//newStr = newStr.replace(/@/g, '<br>');
		this.defaultCharacters = newStr;
		this._element.html(newStr);
	}

}

typeText.prototype.start = function(param){
	var _self = this;

	_self.options = $.extend({}, _self.optionsDefault, param);

	_self.stop();
	var len = $('.type-char', _self._element).length;
	var cnt = 0;

	$('.type-char', _self._element).each(function(i){
			var _this = this;
			$(_this).css({opacity: 0});
			_self.timeout = setTimeout(function(){
				cnt++;
				$(_this).css({opacity: 1});

				if(cnt == len){
					setTimeout(function(){
						_self.options.callback();
					}, _self.options.callbackDelay);
				}

			}, i * _self.options.interval);
	});
}

typeText.prototype.stop = function(){
	var _self = this;

	if(_self.timeout) clearTimeout(_self.timeout);

	for (var i = 0; i < _self.intervals.length; i++) {
			if(_self.intervals[i])
			clearInterval(_self.intervals[i]);
	}
	_self.intervals = [];

	_self._element.html(_self.defaultCharacters);
	$('.type-char', _self._element).css({opacity: 0});
}

function includeModule(moduleName, rootDir, slug = ''){

	let root;
	let pathToRoot = rootDir.split('../');

	$.ajax({
		url: rootDir + 'include/'+moduleName+'.html',
		cache: false,
		async: false, 
		datatype:'html',
		success: function(html){
			html = html.replace(/\{\$root\}/g, rootDir);
			let $html = $(html);

			
			if($html.find('.menus-to-mark').length){
				let $menu = $html.find('.menus-to-mark');
				$menu.find(' > li').addClass('parent');
				let pathName = location.pathname;
	
				let pathAry = pathName ? pathName.split('/') : [];
				let tgtToFind = pathAry ? pathAry[pathAry.length-1] : '';

				$menu.find('a').each((i,elem)=>{					
					if($(elem).attr('href').indexOf(pathName) > -1 && pathName != '/'){
						//console.log($(elem).attr('href'))
						$(elem).parent().addClass('current');
						$(elem).closest('.parent').addClass('current');
					} else if($(elem).attr('href') == rootDir && pathName == '/'){						
            $menu.find(' > li:first').addClass('current');
					}
				});
				
			}

			document.write($html.prop('outerHTML'));
		}
	});

}